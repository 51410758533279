<script>
import Common from '../helpers/Common';

export default {
    props: {
        countries: {
            type: Array,
            default: () => Common.countryList()
        },
        country: String,
        disabled: {
            type: Boolean,
            default: false
        },
        invalid: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            flags: Common.flagByCountry()
        }
    },
    methods: {
        countryChanged(value) {
            this.$emit('country-changed', value);
        },
        getRegionName(countryCode) {
            let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
            return regionNames.of(countryCode);
        },
    }
}
</script>

<template>
    <b-dropdown variant="white" dropup menu-class="w-100" :toggle-class="{'border-danger': invalid}" class="w-100" :disabled="disabled">
        <template v-slot:button-content>
            <div class="d-flex align-items-center" style="gap: 15px;">
                <b-img v-if="country" :src="flags[country]" alt="Country" width="24"></b-img>
                <p class="mb-0">{{ country ? getRegionName(country) : '-- Select Country --' }}</p>
            </div>
            <img class="mr-0" src="@/assets/images/down.svg" alt="down" height="18" width="18" />
        </template>
        <b-dropdown-item @click="countryChanged('')">
            -- Select Country --
        </b-dropdown-item>

        <b-dropdown-item
            v-for="(option, i) in countries"
            :key="`country${i}`"
            @click="countryChanged(option)"
            :link-class="{'active': option === country, 'd-flex flex-wrap': true}"
        >
            <div class="my-auto">
                <div class="d-flex align-items-center" style="gap: 15px;">
                    <b-img :src="flags[option]" alt="Country" width="24"></b-img>
                    {{ getRegionName(option) }}
                </div>
            </div>
        </b-dropdown-item>
    </b-dropdown>
</template>

<style lang="scss" scoped>
::v-deep .dropdown-toggle {
    display: flex;
    height: 44px;
    padding: 18px 25px;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid #DCDAD5;
    min-width: 270px;
}

::v-deep .dropdown-menu {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
    max-height: 50vh;
    overflow-y: scroll;
    min-width: min-content;

    a {
        height: 44px;
        .active {
            border-right: 5px solid #FC6B2D;
            background: rgba(220, 218, 213, 0.30);
        }
    }
}
</style>