<script>
import {
    FETCH_ALL_PAYMENT_CHANNEL_FOR_PREVIEW
} from "@/state/actions.type";
import cloneDeep from 'lodash.clonedeep';
export default {
    props: {
        currency: String,
        isEdit: Boolean,
        selectedPaymentMethods: Object
    },
    data() {
        return {
            logoList: [],
            paymentType: [],
            selectedPaymentTypes: [],
            currentPaymentType: "",
            paymentMethods: {},
            lastSavedPaymentMethods: null
        }
    },
    created() {
        this.loadPaymentMethods();
    },
    computed: {
        chaiPayKey() {
            return this.$store.getters["auth/iamportKey"];
        },
        paymentMethodsFromStore() {
            return this.$store.state.paymentChannels.previewChannels;
        }
    },
    watch: {
        currency() {
            this.loadPaymentMethods();
        }
    },
    methods: {
        loadPaymentMethods() {
            if(this.currency) {
                this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL_FOR_PREVIEW}`, {
                chaiPayKey: this.chaiPayKey,
                currency: this.currency
                }).then(() => {
                    this.paymentMethods = cloneDeep(this.paymentMethodsFromStore);
                    if(this.isEdit && this.selectedPaymentMethods && Object.keys(this.selectedPaymentMethods).length > 0) {
                        for(let key in this.paymentMethods) {

                            this.paymentMethods[key] = this.paymentMethods[key].map((element) => {
                                if(!this.selectedPaymentMethods[key]) {
                                    element.is_enabled = false;
                                } else {
                                    const pmtChannel = this.selectedPaymentMethods[key].find(el => el.payment_method_key === element.payment_method_key);
                                    if(!pmtChannel || !pmtChannel.is_enabled) {
                                        element.is_enabled = false;
                                    }
                                }
                                return element;
                            })
                        }
                    }
                    this.setPaymentMethods();
                });
            }
        },

        setPaymentMethods() {
            this.logoList = [];
            this.selectedPaymentTypes = [];
            this.paymentType = [];

            for(let key in this.paymentMethods) {

                if(this.paymentMethods[key].length > 0) {
                    this.paymentType.push(key);

                    this.paymentMethods[key].map(element => {
                        if(element.is_enabled) {
                            if(!this.selectedPaymentTypes.includes(key)) {
                                this.selectedPaymentTypes.push(key);
                            }
                            this.logoList.push(element.logo)
                        }
                    })
                }
            }
        },

        changeCurrentMethod(value) {
            if(this.currentPaymentType === value) {
                this.currentPaymentType = "";
            } else {
                this.currentPaymentType = value;
            }
        },

        selectPaymentType(value) {
            this.paymentMethods[value] = this.paymentMethods[value].map(element => {
                element.is_enabled = this.selectedPaymentTypes.includes(value);
                return element;
            });
            this.currentPaymentType = value;
        },

        togglePaymentMethod(value) {
            const enabled = this.paymentMethods[value].find(element => element.is_enabled);
            if(!enabled) {
                this.selectedPaymentTypes = this.selectedPaymentTypes.filter(el => el !== value);
            } else {
                if(!this.selectedPaymentTypes.includes(value)) {
                    this.selectedPaymentTypes.push(value);
                }
            }
        },

        toggleAllPaymentMethods(value) {
            this.selectedPaymentTypes = value ? this.paymentType : [];

            for(let key in this.paymentMethods) {

                this.paymentMethods[key] = this.paymentMethods[key].map(element => {
                    element.is_enabled = value;
                    return element;
                })
            }
        },

        saveAll() {
            let chosenPaymentMethods = [];
            for(let key in this.paymentMethods) {
                this.paymentMethods[key].map(element => {
                    if(element.is_enabled) {
                        chosenPaymentMethods.push({
                            payment_channel: element.payment_channel_key,
                            payment_method: element.payment_method_key
                        });
                    }
                });
            }

            let defaultPaymentMethods = true;
            for(let key in this.paymentMethodsFromStore) {
                this.paymentMethodsFromStore[key].map(element => {
                    let findCommon = chosenPaymentMethods.find(pmtMethod => (pmtMethod.payment_channel === element.payment_channel_key && pmtMethod.payment_method === element.payment_method_key));
                    if(!findCommon || !findCommon.is_enabled) {
                        defaultPaymentMethods = false;
                    }
                });
            }

            const response = defaultPaymentMethods ? [] : chosenPaymentMethods;
            this.lastSavedPaymentMethods = cloneDeep(this.paymentMethods);

            this.$emit('payment-methods', response);
            this.setPaymentMethods();
            this.$bvModal.hide('modal-1');
        },

        resetModal(event) {
            if(event.trigger === 'headerclose') {
                this.paymentMethods = cloneDeep(this.lastSavedPaymentMethods ? this.lastSavedPaymentMethods : this.paymentMethodsFromStore);
                this.setPaymentMethods();
            }
            this.currentPaymentType = "";
        }
    }
}
</script>

<template>
    <div>
        <label class="d-block">{{ $t("views.payment_links.payment_methods") }}<span class="text-chai" style="float: right;cursor: pointer;" :disabled="!currency" v-b-modal.modal-1 >Manage 
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M9.61967 3.95312L13.6663 7.99979L9.61967 12.0465M2.33301 7.99979H13.553" stroke="#FC6B2D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg></span>
        </label>
        <div class="d-flex" v-if="currency">
            <b-img v-bind="{ src:logo, width: 30, height: 30, class: 'mx-1' }" v-for="(logo, index) in logoList.slice(0, 5)" rounded="circle" alt="Circle image" :key="index"></b-img>
            <span class="my-auto" style="font-size:16px; font-weight:500; color:rgba(0, 0, 0, 0.5); margin-left: 10px;">{{ logoList.length > 6 ? `+ ${logoList.length - 6} more` : '' }}</span>
            <!-- <b-btn variant="light" class="rounded-circle mx-1" size="sm"><i class="mdi mdi-pencil"></i></b-btn> -->
        </div>
        <div v-else>
            <b-img v-bind="{ blank: true, blankColor: '#777', width: 30, height: 30, class: 'm-1' }" rounded="circle" alt="Circle image"></b-img>
            <b-img v-bind="{ blank: true, blankColor: '#777', width: 30, height: 30, class: 'm-1' }" rounded="circle" alt="Circle image"></b-img>
            <b-img v-bind="{ blank: true, blankColor: '#777', width: 30, height: 30, class: 'm-1' }" rounded="circle" alt="Circle image"></b-img>
            <b-img v-bind="{ blank: true, blankColor: '#777', width: 30, height: 30, class: 'm-1' }" rounded="circle" alt="Circle image"></b-img>
            <b-img v-bind="{ blank: true, blankColor: '#777', width: 30, height: 30, class: 'm-1' }" rounded="circle" alt="Circle image"></b-img>
            <b-img v-bind="{ blank: true, blankColor: '#777', width: 30, height: 30, class: 'm-1' }" rounded="circle" alt="Circle image"></b-img>
            <b-btn variant="light" disabled class="rounded-circle m-1" size="sm"><i class="mdi mdi-pencil"></i></b-btn>
        </div>


        <div v-if="currency">
            <b-modal
                id="modal-1"
                title=""
                @hide="resetModal"
                no-close-on-backdrop
                header-class="modal-header-custom"
                body-class="modal-body-custom"
                footer-class="modal-footer-custom"
            >
                <!-- Payment Types -->
                <div>
                    <p style="font-size: 20px;color: #000;margin-bottom: 8px;">Payment Method</p>
                    <div style="color: rgba(0, 0, 0, 0.7);font-size: 14px;margin-bottom: 30px;font-weight: 325;line-height: 20px;">Select payment methods and channels here you accept from your customer.</div>
                </div>
                <div v-for="(method, index) in paymentType" :key="index" style="margin-bottom: 15px;">

                    <div class="d-flex justify-content-between rounded" @click="changeCurrentMethod(method)" style="cursor: pointer; cursor: pointer;background: rgb(243, 243, 243);background: rgba(220, 218, 213, 0.30);border-top-left-radius: 8px;border-top-right-radius: 8px;padding: 15px 25px;border-radius: 8px;">
                        <div class="d-flex"><b-form-checkbox class="my-auto" v-model="selectedPaymentTypes" @change="selectPaymentType(method)" :checked="selectedPaymentTypes.includes(method)" :value="method"></b-form-checkbox><label class="my-auto" style="cursor: pointer;padding-left: 5px;font-size: 14px;color: #000;">{{ method }}</label></div>
                        <i :class="currentPaymentType === method ? 'mdi-chevron-up' : 'mdi-chevron-down'" class="h4 my-auto mdi"></i>
                    </div>

                    <!-- Payment Methods -->
                    <div v-if="currentPaymentType === method" >
                        <b-form-checkbox v-for="(element, key) in paymentMethods[method]" @change="togglePaymentMethod(method)" :key="key" style="padding-left: 85px; padding-top: 15px;padding-bottom: 15px;border-bottom: 1px #DCDAD5 solid;font-size: 14px;" v-model="paymentMethods[method][key].is_enabled" :value="true" :unchecked-value="false">{{ element.name }}</b-form-checkbox>
                    </div>
                </div>

                <template v-slot:modal-footer>
                    <div class="d-flex justify-content-between w-100">
                        <div style="color: #FC6B2D;font-size: 16px;font-weight: 500;text-decoration: underline;cursor: pointer;padding-top: 16px;" variant="light" @click="toggleAllPaymentMethods(true)">Select All</div>
                        <div style="color: #FC6B2D;font-size: 16px;font-weight: 500;text-decoration: underline;cursor: pointer;padding-top: 16px;" variant="light" @click="toggleAllPaymentMethods(false)">Clear All</div>
                        <div style="color: rgb(252, 107, 45);font-size: 16px;font-weight: 500;cursor: pointer;padding: 16px 25px;background: rgba(220, 218, 213, 0.30);border-radius: 8px;" variant="primary" :disabled="selectedPaymentTypes.length === 0" @click="saveAll">Save</div>
                    </div>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<style scoped>
::v-deep .modal-header-custom{
    border-bottom: none;
    padding-bottom: 0px;
}
::v-deep .modal-body-custom{
    padding: 0px 35px 10px 35px;
}
::v-deep .modal-footer-custom{
    border-top: none;
    padding: 0px 35px 35px 35px;
}
</style>